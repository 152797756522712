import { HttpQuery } from "@/interfaces/httpQuery";
import {
  Tag,
  TagBox,
  TagBoxLoadData,
  TagBoxSummary,
  TagReservation,
  TagReservationResponse,
  TagsFilters,
  TagToSave
} from "@/interfaces/tag";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import { Vue } from "vue-property-decorator";
import { messagesService } from "./messages.service";

class TagManagerService extends HttpService {
  public async getTagBoxSummary(): Promise<TagBoxSummary | null> {
    try {
      this.uri = "/traceability/tag_manager/availability";
      const response = await super.get({}, false);
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  /**
   * Get all sequences paginated and filtered
   * @return Promise<Tag[]>
   */
  public async getTagBoxes(data: TagBoxLoadData): Promise<TagBox[]> {
    try {
      this.uri = "/traceability/tag_manager/sequences";
      const query = this.parseTagBoxQuery(data);
      const response = await super.get(query);
      return response.data.data.map((tagBox: TagBox) => ({
        ...tagBox,
        type: "package",
        created_at: fnsFormatDate(
          new Date(tagBox.created_at),
          FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
        )
      }));
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async deleteMultiple(tags: Tag[]): Promise<any> {
    const model = tags.map((tag: Tag) => {
      return tag.id;
    });
    const url = `/traceability/tag_manager/tags/destroy_collection`;
    try {
      const response: AxiosResponse<any> = await Vue.axios({
        method: "POST",
        url,
        data: model
      });
      messagesService.renderSuccessMessage(
        i18n.t("deleted_successfully").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return e.response;
    }
  }

  /**
   * undo the tag box, is a delete request for the api backend
   * @param model
   */
  public async undo(model: TagBox): Promise<boolean> {
    try {
      this.uri = "/traceability/tag_manager/sequences";
      await super.delete(model);
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }

  /**
   * get the tag box
   * @return Promise<Tag[]>
   */
  public async getTags(
    query: {
      filters: TagsFilters;
      pagination: TablePagination;
    } | null
  ): Promise<Tag[]> {
    try {
      this.uri = "/traceability/tag_manager/tags";
      if (query) {
        this.removeFilters();
        this.buildFilterTag(query.filters);
        this.buildFilterOrder(query.filters);
        this.buildFilterAvailability(query.filters);
        this.query = { ...query.filters, ...this.query };
        if (query.pagination) {
          this.query.page = query.pagination.currentPage;
          this.query.per_page = query.pagination.itemsPerPage;
        }
      }
      const response = await super.get(this.query);
      return response.data.data.map((filters: TagsFilters) => ({
        ...filters,
        type: "package"
      }));
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async createTagBox(model: TagToSave): Promise<boolean> {
    try {
      this.uri = "/traceability/tag_manager/sequences";
      const response = await super.post(model);
      messagesService.renderSuccessMessage("tag_manager.tag_saved");
      return !!response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return false;
    }
  }

  public async getNextTag(): Promise<string> {
    try {
      this.uri = "/traceability/tag_manager/next_tags";
      const response = await super.get({});
      return response.data[0];
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return "";
    }
  }

  public async getTagReservation(
    reservationId: number
  ): Promise<TagReservation | null> {
    try {
      this.uri = `/traceability/tag_manager/reservations/${reservationId}`;
      const response = await super.get({});
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async tagReservation(
    tags: string[]
  ): Promise<TagReservationResponse | null> {
    try {
      this.uri = "/traceability/tag_manager/reservations";
      return await super.post({ tags });
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async tagReservationRelease(
    reservation: TagReservationResponse
  ): Promise<TagReservation | null> {
    try {
      this.uri = `/traceability/tag_manager/reservations/${
        reservation.reservation!.id
      }/release`;
      const response = await super.post({ tags: reservation.reserved });
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  private removeFilters() {
    delete this.query["q[status_noteq]"];
    delete this.query.status;
    delete this.query.order;
    delete this.query.tag;
  }

  private buildFilterOrder(query: TagsFilters) {
    if (query && query.order) {
      this.query["q[tagSequence.operation_uid_contains]"] = query.order;
    } else {
      delete this.query["q[tagSequence.operation_uid_contains]"];
    }
  }
  private buildFilterTag(query: TagsFilters) {
    if (query && query.tag !== "") {
      this.query["q[tag_contains]"] = query.tag;
    } else {
      delete this.query["q[tag_contains]"];
    }
  }

  private buildFilterAvailability(query: TagsFilters) {
    if (query && query.availability === "yes") {
      this.query.scopes = "available";
    } else if (query && query.availability === "no") {
      this.query.scopes = "assigned";
    } else {
      this.query.scopes = "";
    }
  }

  private parseTagBoxQuery(data: TagBoxLoadData): HttpQuery {
    const query: HttpQuery = {
      page: data.pagination.currentPage,
      per_page: data.pagination.itemsPerPage
    };

    if (data.filters.starting_tag_id) {
      query["q[starting_tag_id_contains]"] = data.filters.starting_tag_id;
    }

    if (data.filters.operation_uid) {
      query["q[operation_uid_contains]"] = data.filters.operation_uid;
    }

    return query;
  }
}

export const tagManagerService: TagManagerService = new TagManagerService();
