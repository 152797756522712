import { TagReservation, TagReservationResponse } from "@/interfaces/tag";
import { hasMetrcIntegrations } from "@/router.utils";
import { tagManagerService } from "@/services/tagManager.service";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Template from "./NextTag.template.vue";

const TAG_LENGTH = 24;

@Component({
  mixins: [Template],
  $_veeValidate: {
    name(this: NextTagComponent) {
      return this.name;
    },
    value(this: NextTagComponent) {
      return this.value;
    }
  }
})
export default class NextTagComponent extends Vue {
  @Prop({ required: true })
  public name!: string;
  @Prop({ default: "" })
  public placeholder!: string;
  @Prop({ required: true })
  public value!: string;
  @Prop({ default: false })
  public disabled!: boolean;
  @Prop({ default: false })
  public hideDetails!: boolean;
  @Prop({ default: () => [] })
  public errorMessages!: string | string[];
  protected hasMetrcIntegrations: boolean = hasMetrcIntegrations();

  protected isFetchingTag: boolean = false;
  protected reservation: TagReservationResponse | null = null;

  protected onUpdateInput(value: string) {
    this.$emit("input", value);
  }

  protected async onChange(tag: string) {
    this.$emit("change", tag);
    if (
      this.hasMetrcIntegrations &&
      tag.length === TAG_LENGTH &&
      (!this.reservation || !this.reservation.reserved.includes(tag))
    ) {
      this.reservationRelease(this.reservation);
      const reservation = await tagManagerService.tagReservation([tag]);

      if (reservation === null) {
        return;
      }

      this.reservation = { ...reservation };
    }
  }

  protected async getNextTag(): Promise<void> {
    if (this.hasMetrcIntegrations) {
      this.isFetchingTag = true;
      const nextTag = await tagManagerService.getNextTag();
      if (nextTag) {
        this.reservationRelease(this.reservation);
        const reservation = await tagManagerService.tagReservation([nextTag]);
        this.reservation = reservation ? { ...reservation } : null;
        this.$emit("input", nextTag);
        this.$emit("change", nextTag);
      }
      this.isFetchingTag = false;
    }
  }

  protected async reservationRelease(
    reservation: TagReservationResponse | null
  ) {
    if (reservation && reservation.reservation) {
      const currentReservation = await tagManagerService.getTagReservation(
        reservation.reservation!.id
      );
      if (currentReservation!.status === "RESERVED") {
        await tagManagerService.tagReservationRelease(reservation);
      }
    }
  }
}
